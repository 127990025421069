@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.contact {
    padding: 1rem;
    padding-bottom: 2rem;

    @include tablet {
        padding: 3.125rem;
    }
    @include desktop {
        padding: 7.0625rem;
    }

    &__title {
        @include section-header;
        align-self: flex-start;

        border-bottom: 3px solid  $Cool-Black;
        padding: 0 15px 4px 15px;
        width: fit-content;

        @include tablet {
            padding: 0 15px 8px 15px;
        }

        margin-bottom: 2.5rem;
    }

    input {
        border: 2px solid $Dim-Gold;
        padding: 6px 20px;
        border-radius: 10px;
        margin-bottom: 18px;
        @include tablet {
            padding: 10px 20px;
        }
    }

    label {
        margin-left: 10px;
        font-weight: 600;
    }

    textarea {
        resize: vertical;
        border: 2px solid $Dim-Gold;
        padding: 6px 20px;
        border-radius: 10px;
        margin-bottom: 18px;
    }

    .f-response {
        width: 100%;
        text-align: center;
        border-radius: 10px;
        padding: 10px;
        font-weight: 600;
        color: white;

        &--success {
            background-color: rgb(72, 173, 94);
        }

        &--error {
            background-color: rgb(219, 115, 115);
        }
    }

    .c_form {
        display: flex;
        flex-direction: column;

        &__up {
            display: flex;
            flex-direction: column;
            @include tablet {
                flex-direction: row;
                justify-content: space-between;
            }

            div {
                display: flex;
                flex-direction: column;

                @include tablet {
                    width: 49%;
                }
            }

        }

        &__button {
            border: none;
            background-color: $Dim-Gold;
            padding: 8px 50px;
            font-weight: bold;
            border-radius: 25px;
            transition: 0.3s;
            font-size: 1rem;
            margin-top: 10px;
            z-index: 90;
            position: relative;
    
            &:hover {
                color: white;
                background-color: $Gold;
                padding: 8px 60px;
            }

            @include tablet {
                width: 30%;
            }
        }
    }
}