@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.footer {
    text-align: center;
    background-image: url('../../assets/images/footer-background.jpeg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100vw;
    height: 800px;
    @include body-copy;

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 800px;
        padding: 0 16px;
        @include tablet {
            
        }

        @include desktop {
            flex-direction: row;
            justify-content: space-around;
        }

        &--img {
            width: 50vw;
            height: 50vw;
            z-index: 90;
            filter: invert(100%) brightness(500%);
            user-select: none;
            margin-bottom: 20px;

            @include tablet {
                width: 30vw;
                height: 30vw;
            }

            @include desktop {
                width: 25vw;
                height: 25vw;
                margin: 0 2%;
            }
        }

        &--learn {
            color: white;
            z-index: 90;
            margin-bottom: 20px;

            @include desktop {
                width: 33%;
            }

            .text {
                color: white;

                @include tablet {
                    margin: 0;
                }
            }

            .button {
                border: none;
                background-color: white;
                padding: 8px 50px;
                font-weight: bold;
                border-radius: 25px;
                transition: 0.3s;
                font-size: 1rem;
                margin-top: 10px;

                @include tablet {
                    margin-right: 40%;
                    margin-top: 25px;
                }

                @include desktop {
                    margin-right: 0;
                }
    
                &:hover {
                    color: white;
                    background-color: $Gold;
                    padding: 8px 60px;
                }
            }
        }

        &--social {
            z-index: 90;
            margin-bottom: 50px;
            @include section-header;
            color: white;

            @include desktop {
                width: 29%;
            }

            .title {
                border-bottom: 3px solid white;
                padding: 0 15px 10px 15px;
                margin-bottom: 0;
                margin-top: 0.8rem;

                @include tablet {
                    padding: 0 15px 20px 15px;
                }
                @include desktop {
                     margin: 0 20%;
                     text-wrap: nowrap;
                }
            }

            .insta {
                margin-right: 30px;
            }

            svg {
                width: 50px;
                height: 50px;
                padding: 8px;
                margin-top: 10px;
                color: $Cool-Black;
                @include desktop {
                    margin-top: 25px;
               }

                background-color: white;
                border-radius: 40%;

                transition: 0.3s;

                &:hover {
                    background-color: $Gold;
                    transform: scale(1.2)
                }
            }
        }
    }

    &__bottom {
        position: absolute;
        bottom: 20px;
        z-index: 90;
        width: 100vw;
        color: white;

            &--link {
                color: white;
                transition: 0.2s;
                text-decoration: underline;

                &:hover {
                    color: $Gold;
                }
            }
        }
}

.footer::after {
    content: '';
    background-color: $Cool-Black;
    opacity: 0.9;
    width: 100vw;
    height: 800px;
    position: absolute;
    top: 0;
    left: 0;
    @include tablet {
        height: 800px;
    }
}