@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.nav {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    z-index: 99;
    user-select: none;
    transition: background 0.6s ease-in-out;

    &--active {
        background-color: rgba($color:  $Gold, $alpha: 0.45);
        backdrop-filter: blur(10px); //!FIX THE BLUR
    }

    padding: 5px;
    @include tablet {
        padding: 5px 3.125rem;
        flex-direction: row;
        justify-content: space-between;
    }
    @include desktop {
        padding: 5px 7rem;
    }

    &__logo {

        &--img {
            width: 40px;
            height: 40px;
            filter: invert(100%) brightness(500%); //Make the logo white
            transition: 0.2s;
            @include tablet {
                width: 50px;
                height: 50px;
            }

            &:hover {
                filter: invert(0%) brightness(0%); //Make the logo back again
            }
        }
    }

    &__links {


        @include tablet {
            margin-top: 0;
        }
        a {
            text-decoration: none;
        }

        &--item {
            text-decoration: none;
            padding: 0 0.6rem;
            @include nav-links;
            transition: 0.2s;

            &:hover {
                color: black;
                text-decoration: underline;
            }
        }
    }
}
