@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  // removing default styling
  margin: 0;
  padding: 0;
  max-width: 100vw;
  overflow-x: hidden;

  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

button {
  cursor: pointer;
  user-select: none;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/*1? Customizing scrollbar*/
/* width */
// ::-webkit-scrollbar {
//     width: 8px;
//   }
  
//   /* Track */
//   ::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 5px rgb(189, 202, 219); 
//     border-radius: 10px;
//   }
   
//   /* Handle */
//   ::-webkit-scrollbar-thumb {
//     background: gold; 
//     border-radius: 10px;
//   }
  
//   /* Handle on hover */
//   ::-webkit-scrollbar-thumb:hover {
//     background: #4a626f; 
//   }