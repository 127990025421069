@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.events {
    background-color: white;
    color: black;
    padding: 1rem;
    margin: auto;
    display: flex;
    flex-direction: column;

    &__title {
        @include section-header;
        border-bottom: 3px solid $Cool-Black;
        padding: 0 15px 4px 15px;
        width: fit-content;

        @include tablet {
            padding: 0 15px 8px 15px;
        }
    }

    @include tablet {
        padding: 3.125rem;
    }
    @include desktop {
        padding: 7.0625rem;
    }

    .e-container {
        max-width: 1200px;
        display: flex;
        align-self: center;
        justify-content: space-between;
        border-bottom: 3px solid $Dim-Gold;
        margin-bottom: 1.5rem;

        &__date {
            &--month {
                font-size: 1rem;
                margin: 0;
                color: $Gold;
                font-weight: bold;
                @include tablet {
                    font-size: 1.25rem;
                }

                &.important {
                    color: $Blue;
                }
            }
            &--day {
                font-size: 3rem;
                margin: 0;
                font-weight: bold;
                @include tablet {
                    font-size: 4rem;
                }

                &.important {
                    color: $Blue;
                }
            }
        }


        &__activities {

            .activitie {
                padding-left: 1rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
    
                @include tablet {
                    flex-direction: row;
                    justify-content: space-between;
                    padding-left: 2rem;
                }

                &__time {
                    font-size: 1rem;
                    margin: 0;
                    position: absolute;
                    color: $Gold;
                    font-weight: bold;
                    margin-top: 4px;
                    align-self: flex-start;

                    &.important {
                        color: $Blue;
                    }
                }
    
                &__description, &__description2 {
                    font-size: 1rem;
                    padding-top: 15px;
                    @include tablet {
                       width: 65%;
                       font-size: 1.25rem;
                    }
                    @include desktop {
                        width: 78%;
                     }
                }
    
                &__button {
                    width: 100%;
    
                    border: none;
                    background-color: $Cool-Black;
                    color: $Gold;
                    padding: 8px 50px;
                    font-weight: bold;
                    border-radius: 25px;
                    transition: 0.3s;
                    font-size: 1rem;
                    margin-top: 10px;
                    margin-bottom: 1rem;
    
                    @include tablet {
                        width: fit-content;
                    }
    
                    @include desktop {
                    }
        
                    &:hover {
                        color: white;
                        background-color: $Gold;
                        padding: 8px 60px;

                        @include tablet {
                            padding: 8px 50px;
                        }
                    }
                }
            }
            
        }
    }
}