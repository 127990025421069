@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.social {
    position: fixed;
    bottom: 0;
    right: 0;
    opacity: 0.1;
    z-index: 1;
    transition: 0.3s ease-in-out;
    display: flex;
    align-items: end;
    flex-direction: column;
    padding: 1rem;
    z-index: 100;

    @include tablet {
        padding: 1.5625rem;
    }

    .css-13cymwt-control, .css-t3ipsp-control {        
        padding: 8px 50px;
        border-radius: 10px;
        margin-top: 15px;
        margin-bottom: 10px;
        border: 2px solid $Cool-Black;

        svg {
            display: none;
        }

    }

    .css-1xc3v61-indicatorContainer, .css-1u9des2-indicatorSeparator {
        display: none;
    }

    .css-1dimb5e-singleValue {
        color: black;
        font-weight: 600;
    }

    .css-14h4o58-menu {
        border-radius: 15px;
    }
}

.social__icon {
    position: fixed;
    bottom: 0;
    right: 0;
    opacity: 0.1;
    z-index: 1;
    transition: 0.3s ease-in-out;
    display: flex;
    align-items: end;
    flex-direction: column;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    z-index: 99;
    
    @include tablet {
        padding-right: 1.5rem;
        margin-bottom: 0.7rem;
    }

    svg {
        width: 40px;
        height: 40px;
        padding: 8px;
        margin-top: 15px;
        color: $Cool-Black;

        background-color: $Dim-Gold;
        border-radius: 40%;

        transition: 0.3s;

        &:hover {
            background-color: $Gold;
            transform: scale(1.2)
        }
    }

    &--insta {
        transform: translateY(-130px);
    }

    &--face {
        transform: translateY(-70px);
    }
}


.social--active {
    opacity: 1;
}