@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.faq {
    padding: 1rem;

    @include tablet {
        padding: 3.125rem;
    }
    @include desktop {
        padding: 7.0625rem;
        padding-top: 0rem;
        padding-bottom: 0rem
    }

    &__title {
        @include section-header;
        border-bottom: 3px solid $Cool-Black;
        padding: 0 15px 4px 15px;
        width: fit-content;
        margin: 0;
        margin-bottom: 2rem;

        @include tablet {
            padding: 0 15px 8px 15px;
        }
    }

    .arrow-image {
        transform: scale(1.6);
    }

    .faq-row.styles_faq-row__2YF3c {
        border-bottom: 3px solid $Cool_Black;
    }
    //FAQ Question title
    .row-title-text.styles_row-title-text__1MuhU {
        margin: 1rem 0;
        font-weight: 500 !important;
        @include faq-title;
    }
    //FAQ Question content
    .styles_row-content-text__2sgAB {
        margin: 0 0 1rem 0;
        @include question-copy;
    }
}