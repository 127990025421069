@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.our-history {

    padding: 1rem;
    padding-bottom: 0;
    @include tablet {
        padding: 3.125rem;
        padding-bottom: 0;
    }
    @include desktop {
        padding: 7.0625rem;
        padding-bottom: 0;
    }

    &__title {
        @include s-section-header;
        margin-top: 3rem;
        margin-bottom: 1rem;
        border-bottom: 3px solid $Cool-Black;
        padding: 0 15px 2px 15px;
        width: fit-content;
        z-index: 999999999999999;
        color: $Cool-Black;

        @include tablet {
            padding: 0 15px 8px 15px;
        }
    }

    &__paragraph {
        @include body-copy;
        color: $Cool-Black;
        max-width: 94vw;
        margin: 1.5rem 0 1.5rem 0;
    }

    img {
        width: 80vw;
        margin-left: 6vw;
        border-radius: 10px;
        user-select: none;

        @include tablet {
            width: 60vw;
            margin-left: 15vw;
        }
    }

}