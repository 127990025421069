@use './variables' as *;
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

@mixin tablet {
    @media (min-width: 768px) {
        @content;
    }
}
@mixin desktop {
    @media (min-width: 1280px) {
        @content;
    }
}

@mixin nav-links {
    //2? This will be applied when the device is a phone
    font-size: 0.8rem;
    line-height: 1.25rem;
    font-weight: 700;
    color: white;

   //This will be applied when the device is a tablet or desktop
   @media (min-width: 768px) {
       font-size: 1.2rem;
       line-height: 1.25rem;
       font-weight: 700;
   }
}

@mixin font-hero {
    //2? This will be applied when the device is a phone
    font-size: 2.4rem;
    line-height: 2rem;
    font-weight: 700;
    color: white;
    font-family: 'Libre Baskerville', serif;

    //This will be applied when the device is a tablet
    @media (min-width: 768px) {
        font-size: 4rem;
        line-height: 165%;
        font-weight: 700;
    }

    //This will be applied when the device is a desktop
    @media (min-width: 1280px) {
        font-size: 4.5rem;
        line-height: 180%;
        font-weight: 700;
    }
}

@mixin section-header {
    //2? This will be applied when the device is a phone
    font-size: 1.2rem;
    line-height: 1.25rem;
    font-weight: 700;
    color: $Cool-Black;
    user-select: none;

   //This will be applied when the device is a tablet or desktop
   @media (min-width: 768px) {
       font-size: 2rem;
       line-height: 1.25rem;
       font-weight: 700;
   }
}

@mixin s-section-header {
    //2? This will be applied when the device is a phone
    font-size: 1.2rem;
    line-height: 1.25rem;
    font-weight: 700;
    color: $Cool-Black;
    user-select: none;

   //This will be applied when the device is a tablet or desktop
   @media (min-width: 768px) {
       font-size: 1.4rem;
       line-height: 1.25rem;
       font-weight: 700;
   }
}

@mixin body-copy {
    //2? This will be applied when the device is a phone
    font-size: 0.85rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: $Cool-Black;

   //This will be applied when the device is a tablet or desktop
   @media (min-width: 768px) {
       font-size: 1.3rem;
       line-height: 1.25rem;
       font-weight: 400;
   }
}


@mixin faq-title {
    //2? This will be applied when the device is a phone
    font-size: 1.125rem !important;
    line-height: 1.25rem;
    font-weight: 500;

   //This will be applied when the device is a tablet
   @media (min-width: 768px) {
       font-size: 1.375rem !important;
       line-height: 1.25rem;
       font-weight: 400;
   }

    //This will be applied when the device is a desktop
    @media (min-width: 1280px) {
        font-size: 1.625rem !important;
        line-height: 1.25rem;
        font-weight: 400;
    }
}


@mixin question-copy {
    //2? This will be applied when the device is a phone
    font-size: 1rem !important;
    line-height: 1.25rem;
    font-weight: 400;
    color: $Cool-Gray !important;

    //This will be applied when the device is a tablet or desktop
    @media (min-width: 768px) {
        font-size: 1.2rem !important;
        line-height: 1.25rem;
        font-weight: 400;
    }
}




