@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.hero {
    position: relative;
    width: 100vw;
    height: 600px;
    overflow: hidden;

    @include tablet {
        height: 800px;
    }

    &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        overflow: hidden;
        min-height: 500px;

        &--background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            min-height: 500px;

        }
    }

    &__text {
        z-index: 90;
        position: absolute;
        top: 30%;
        left: 10%;
        border-left: 8px solid white;
        @include font-hero;
        line-height: normal;
        padding-left: 10px;
        padding: 10px 0 20px 10px;
        width: 60vw;
        height: fit-content;

        @include tablet {
            width: 50vw;
        }
        @include desktop {
            width: 35vw;
        }

        &--title {
            margin: 0;
        }

        &--button {
            button {
                @include body-copy;
                border: none;
                background-color: white;
                padding: 8px 30px;
                font-weight: bold;
                border-radius: 25px;
                transition: 0.3s;

                @include tablet {
                    transform: translateY(-30px);
                }

                &:hover {
                    color: white;
                    background-color: $Gold;
                    padding: 8px 40px;
                }
            }
        }
    }

    &::after {
        content: '';
        background-color: #000000;
        opacity: 0.2;
        animation: 1.5s ease-in-out appearance;
        width: 100vw;
        height: 600px;
        position: absolute;
        top: 0;
        left: 0;

        @include tablet {
            height: 800px;
        }

        @keyframes appearance {
            from {
                opacity: 0.6;
            }

            to {
                opacity: 0.1;
            }
        }
    }
}
