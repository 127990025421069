@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.about {
    background-image: url('../../assets/images/about-background.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    position: relative;
    max-width: 100vw;
    height: 400px;

    padding: 1rem;
    @include tablet {
        padding: 3.125rem;
        height: 600px;
    }
    @include desktop {
        padding: 7.0625rem;
        background-position: 0% 20%;
    }
    

    &__title {
        @include section-header;
        margin-top: 3rem;
        margin-bottom: 2rem;
        border-bottom: 3px solid white;
        padding: 0 15px 4px 15px;
        width: fit-content;
        z-index: 999999999999999;
        color: white;
        z-index: 90;
        position: relative;

        @include tablet {
            padding: 0 15px 8px 15px;
        }
    }

    &__paragraph {
        @include body-copy;
        color: white;
        max-width: 94vw;
        margin: 1.5rem 0 1.5rem 0;
        z-index: 90;
        position: relative;
    }

    &__button {
        border: none;
        background-color: white;
        padding: 8px 50px;
        font-weight: bold;
        border-radius: 25px;
        transition: 0.3s;
        font-size: 1rem;
        margin-top: 10px;
        z-index: 90;
        position: relative;

        &:hover {
            color: white;
            background-color: $Gold;
            padding: 8px 60px;
        }
    }

    &::after {
        content: '';
        background-color: #2a2a2a;
        opacity: 0.6;
        width: 100vw;
        height: 400px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        @include tablet {
            height: 600px;
        }
    }
}