@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.testimonials {
    background-color: $Cool-Black;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;

    @include tablet {
        padding: 3.125rem;
    }
    @include desktop {
        padding: 7.0625rem;
    }

    &__title {
        @include section-header;
        color: white;
        align-self: flex-start;

        border-bottom: 3px solid  white;
        padding: 0 15px 4px 15px;
        width: fit-content;

        @include tablet {
            padding: 0 15px 8px 15px;
        }

        margin-bottom: 2.5rem;
    }

    .card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 2rem;
        max-width: 1000px;

        @include tablet {
            flex-direction: row;
        }
        &__picture{
            text-align: center;
            @include tablet {
                margin-right: 2rem;
            }
            &--img {
                background-color: $Gold;
                width: 160px;
                height: 160px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                    user-select: none;
                }
            }
            &--name {
                color: $Gold;
                font-size: 1.25rem;
                margin: 0;
                margin-top: 1rem;
                font-family: 'Libre Baskerville', serif;
            }
        }

        &__description {

            &--text {
                @include body-copy;
                color: white;
                margin: 0;
            }

            &--more {
                @include body-copy;
                color: $Gold;
                user-select: none;
                transition: 0.2s ease-in-out;
                margin-top: 0.5rem;
                width: fit-content;
                user-select: none;

                &:hover {
                    color: $Dim_Gold;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            &--less{
                @include body-copy;
                color: rgb(150, 165, 176);
                user-select: none;
                transition: 0.2s ease-in-out;
                margin-top: 0.5rem;
                width: fit-content;
                user-select: none;

                &:hover {
                    color: $Dim-Gold;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            &--quote1 {
                color: $Gold;
                width: 25px;
                height: 25px;
                transform: scale(1.5);
            }

            &--quote2 {
                color: $Gold;
                width: 25px;
                height: 25px;
                transform: rotate(180deg) scale(1.5) translateY(-5px);
            }
        }
    }
}